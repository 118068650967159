import { useState, useEffect } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';
import { useDeleteBuilder } from './hookBuilder'

export function useQirStatus(refresh) {

    const { setAlert } = useAlert();

    const [loading, setLoading] = useState(true);
    const [qirStatusList, setQirStatusList] = useState([]);

    useEffect(() => {
        getQirStatusList();
    }, [refresh]);

    async function getQirStatusList() {

        await fetchDataAuthenticated("api/qirStatus")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setQirStatusList(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to get QIR statuses", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { qirStatusList, loading };
}

export function useQirStates() {

    const { setAlert } = useAlert();

    const [loading, setLoading] = useState(true);
    const [qirStates, setQIRStates] = useState([]);

    useEffect(() => {
        getQirStates();
    }, []);

    async function getQirStates() {

        await fetchDataAuthenticated("api/qirStatus/states")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setQIRStates(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to get QIR states", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { qirStates, loading };
}

export function useGetQIRStatusByBusinessUnit(businessUnitId) {

    const { setAlert } = useAlert();

    const [qirStatuses, setQirStatuses] = useState([]);

    useEffect(() => {
        if (businessUnitId) {
            getQIRStatus(businessUnitId);
        }
    }, [businessUnitId]);

    async function getQIRStatus(businessUnitId) {

        await fetchDataAuthenticated(`api/businessUnit/${businessUnitId}/qirStatus`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setQirStatuses(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to get QIR status list for business unit", error.message);
            })
    }

    return qirStatuses;
}

export function useSaveQIRStatus() {

    const { setAlert } = useAlert();
    const [loading, setLoading] = useState(true);

    async function saveQIRStatus(qirStatus) {

        let url = "api/qirStatus";
        let method = "POST";
        if (qirStatus.id != null) {
            url += `/${qirStatus.id}`;
            method = "PUT";
        }

        await fetchDataAuthenticated(url, method, JSON.stringify(qirStatus))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(() => {
                console.log(`Saved QIR status ${qirStatus.id}`, qirStatus)
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to save QIR status", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { saveQIRStatus, loading }
}

export function useDeleteQIRStatus(qirStatus) {
    let url = `api/qirStatus/${qirStatus.id}`;
    let successMessage = `Successfully deleted status ${qirStatus.name}`;
    let errorMessage = "Failed to delete status";
    const { deleteFunction, loading } = useDeleteBuilder(url, successMessage, errorMessage);

    return { deleteFunction, loading };
}