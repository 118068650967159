import { useState, useEffect } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';
import { useDeleteBuilder } from './hookBuilder'

export function useGetCommentReasonCodes(refresh) {

    const { setAlert } = useAlert();

    const [loading, setLoading] = useState(true);
    const [commentReasonCodes, setCommentReasonCodes] = useState([]);

    useEffect(() => {
        getCommentReasonCodes();
    }, [refresh]);

    async function getCommentReasonCodes() {

        await fetchDataAuthenticated("api/commentReasonCodes")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setCommentReasonCodes(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to get comment reason codes", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { commentReasonCodes, loading };
}

export function useGetCommentReasonCodesByBusinessUnit(businessUnitId) {

    const { setAlert } = useAlert();

    const [commentReasonCodes, setCommentReasonCodes] = useState([]);

    useEffect(() => {
        if (businessUnitId) {
            getCommentReasonCodes(businessUnitId);
        }
    }, [businessUnitId]);

    async function getCommentReasonCodes(businessUnitId) {

        await fetchDataAuthenticated(`api/businessUnit/${businessUnitId}/commentReasonCodes`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setCommentReasonCodes(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to get comment reason codes", error.message);
            })
    }

    return commentReasonCodes;
}

export function useSaveCommentReasonCodes() {

    const { setAlert } = useAlert();
    const [loading, setLoading] = useState(true);

    async function saveCommentReasonCodes(commentReasonCode) {

        let url = "api/commentReasonCodes";
        let method = "POST";
        if (commentReasonCode.id != null) {
            url += `/${commentReasonCode.id}`;
            method = "PUT";
        }

        await fetchDataAuthenticated(url, method, JSON.stringify(commentReasonCode))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(() => {
                console.log(`Saved comment reason code ${commentReasonCode.id}`, commentReasonCode)
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to save comment reason code", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { saveCommentReasonCodes, loading }
}

export function useDeleteCommentReasonCodes(commentReasonCode) {
    let url = `api/commentReasonCodes/${commentReasonCode.id}`;
    let successMessage = `Successfully deleted comment reason code ${commentReasonCode.name}`;
    let errorMessage = "Failed to delete comment reason code";
    const { deleteFunction, loading } = useDeleteBuilder(url, successMessage, errorMessage);

    return { deleteFunction, loading };
}