import { useState, useEffect } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';
import { useDeleteBuilder } from './hookBuilder'

export function useGetGrades(refresh) {

    const { setAlert } = useAlert();

    const [loading, setLoading] = useState(true);
    const [grades, setGrades] = useState([]);

    useEffect(() => {
        getGrades();
    }, [refresh]);

    async function getGrades() {

        await fetchDataAuthenticated("api/grade")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setGrades(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to get grades", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { grades, loading };
}

export function useGetGradesByBusinessUnit(businessUnitId) {

    const { setAlert } = useAlert();

    const [grades, setGrades] = useState([]);

    useEffect(() => {
        if (businessUnitId) {
            getGrades(businessUnitId);
        }
    }, [businessUnitId]);

    async function getGrades(businessUnitId) {

        await fetchDataAuthenticated(`api/businessUnit/${businessUnitId}/grades`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setGrades(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to get grades", error.message);
            })
    }

    return grades;
}

export function useSaveGrade() {

    const { setAlert } = useAlert();
    const [loading, setLoading] = useState(true);

    async function saveGrade(grade) {

        let url = "api/grade";
        let method = "POST";
        if (grade.id != null) {
            url += `/${grade.id}`;
            method = "PUT";
        }

        await fetchDataAuthenticated(url, method, JSON.stringify(grade))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(() => {
                console.log(`Saved grade ${grade.name}`, grade)
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to save grade", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { saveGrade, loading }
}

export function useDeleteGrade(grade) {
    let url = `api/grade/${grade.id}`;
    let successMessage = `Successfully deleted grade ${grade.name}`;
    let errorMessage = "Failed to delete grade";
    const { deleteFunction, loading } = useDeleteBuilder(url, successMessage, errorMessage);

    return { deleteFunction, loading };
}