import { useState, useEffect } from 'react';
import useAlert from '../components/Alerts/useAlert';
import { fetchDataAuthenticated } from '../utilities/HttpUtils';
import { useDeleteBuilder } from './hookBuilder'

export function useGetCategories(refresh) {

    const { setAlert } = useAlert();

    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        getCategories();
    }, [refresh]);

    async function getCategories() {

        await fetchDataAuthenticated("api/category")
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setCategories(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to get categories", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { categories, loading };
}

export function useGetCategoriesByBusinessUnit(businessUnitId) {

    const { setAlert } = useAlert();

    const [categories, setCategories] = useState([]);

    useEffect(() => {
        if (businessUnitId) {
            getCategories(businessUnitId);
        }
    }, [businessUnitId]);

    async function getCategories(businessUnitId) {

        await fetchDataAuthenticated(`api/businessUnit/${businessUnitId}/categories`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(data => {
                setCategories(data);
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to get categories", error.message);
            })
    }

    return categories;
}

export function useSaveCategory() {

    const { setAlert } = useAlert();
    const [loading, setLoading] = useState(true);

    async function saveCategory(category) {

        let url = "api/category";
        let method = "POST";
        if (category.id != null) {
            url += `/${category.id}`;
            method = "PUT";
        }

        await fetchDataAuthenticated(url, method, JSON.stringify(category))
            .then(async response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error(await response.text());
            })
            .then(() => {
                console.log(`Saved category ${category.id}`, category)
            })
            .catch(error => {
                console.error(error.message);
                setAlert("danger", "Error", "Failed to save category", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    }

    return { saveCategory, loading }
}

export function useDeleteCategory(category) {
    let url = `api/category/${category.id}`;
    let successMessage = `Successfully deleted category ${category.name}`;
    let errorMessage = "Failed to delete category";
    const { deleteFunction, loading } = useDeleteBuilder(url, successMessage, errorMessage);

    return { deleteFunction, loading };
}
